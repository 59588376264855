.et-admin {
    @media screen and ( max-width: 768px ){
        .et-tab-tabbtn {
            padding: 0 1%;
        }
    }

    @media screen and ( min-width: 768px ){
        .et-tab-tabbtn {
            padding: 0 10%;
        }
    }
}
@primary-color: #5C6AC4;