.product-edit-title{
    margin-bottom: 8px;
    font-size: 22px;
}
.out-of-stock-message{
    margin-top: 10px;
}
.product-edit-editor{
    .editor {
        .demo-editor {
            border-width: 1px !important;
            border-style: solid !important;
            border-color: rgb(241, 241, 241) !important;
            border-image: initial !important;
            padding: 12px 5px !important;
            border-radius: 2px !important;
        }

        .public-DraftStyleDefault-block {
            margin: 0 0;
        }

        a {
            color: #4E5154;
        }
    }
    .out-of-stock{
        margin-top: 10px;
    }
    
    .range {
        padding: 10px 0 0 0;

        .Polaris-RangeSlider-DualThumb__Output {
            opacity: 1;
            visibility: visible;
            bottom: var(--p-range-slider-thumb-size-active, var(--p-range-slider-thumb-size-base, 3rem));
        }

        h2{
            margin-bottom: 40px;
        }
    }
}

.product-edit-zones{
    margin-top: 20px;
}
@primary-color: #5C6AC4;