.et-product-list {
    .Polaris-Thumbnail {
        border:0px;
        img{
            height: 40px;
            width: 40px;
        }
    }
    .Polaris-Layout {
        display: block;

        .Polaris-Card {
            border-radius: 0 0 3px 3px;
        }
    }
    .Polaris-Card .Polaris-Card__Header {
        height: 76px;
        line-height: 42px;
        border-bottom: 1px solid #C8C8C8;
    }

    .change-product{
        width: 100%;
    }
    .Polaris-ActionMenu-MenuAction__IconWrapper {
        display: none;
    }

    .Polaris-Page__Content, .Polaris-Page-Header--hasActionMenu {
        padding: 0px;
        margin: 0px;
    }

    .Polaris-Page-Header__ActionMenuWrapper {
        margin-top: 0;
    }

    .Polaris-ActionMenu__ActionsLayout {
        display: block;
    }
   
    .Polaris-DataTable__Cell:nth-child(3) {
        width: 50%;
    }
    .Polaris-DataTable__Cell:nth-child(4) {
        width: 5%;
       
    }

    .Polaris-Badge{
        border:0px;
    }
    .Polaris-DataTable__Cell:nth-child(5) {
        width: 35%;
    }

    .Polaris-DataTable__Cell:nth-child(6) {
        width: 5%;
    }
    

    // .Polaris-DataTable__Cell:nth-child(2) {
    //     padding-top: 25px;
    // }

    .Polaris-DataTable__Cell--numeric {
        text-align: left;
        white-space: normal;
    }

    .Polaris-Page {
        padding: 0;
    }

    .Polaris-EmptyState__Details {
        max-width: 100%;
    }

    .Polaris-EmptyState--newDesignLanguage, .Polaris-EmptyState--withinContentContainer {
        padding: 3rem 2rem;
    }

    .Polaris-EmptyState__Section {
        background-color: #F7F9FA;
    }

    .Polaris-DisplayText--sizeSmall {
        font-size: 1.5rem;
    }

    .pagination {
        margin: auto;
        padding: 2.4rem 1.6rem;
        text-align: center;
        border-top: .1rem solid var(--p-border-subdued,#dfe3e8);
    }

    .Polaris-Frame {
        min-height: auto;
    }

    .delete-search-add-product-list-text {
        cursor: pointer;
    }

    .view-product {
        margin-top: 2px;
        span {
            display: inline-block;
            cursor: pointer;

            svg:hover {
                fill:#007ace;
            }
        }
    }

    .Polaris-DataTable__Cell--verticalAlignTop{
        vertical-align: middle;
    }

    .product-list-loding{
        .Polaris-Spinner{
            position: absolute;
            top: 30rem;
            left: 50rem;
        }
    }
    // .Polaris-Thumbnail--sizeSmall{
    //     width: 5rem;
    // }
    .product-list-title{
    //     display: flex;
    //     justify-content: space-evenly;
    //     .product-list-icon{
    //         display: flex;
    //         align-items: center;
    //     }
        cursor:pointer;
    }
    // .Polaris-DataTable__TableRow + .Polaris-DataTable__TableRow .Polaris-DataTable__Cell{
    //     border-top:0px;
    //         // solid var(--p-divider, #dfe3e8);
    // }
    // .Polaris-DataTable__TableRow{
    //         display: flex;
    //         align-items: center;
    //         border-bottom: 0.1rem solid var(--p-divider, #dfe3e8);
    // }
    // .Polaris-DataTable__TableRow::hover{
    //     background-color: red;
    // }
    .tr-background{
        background-color: #d5d4ee;
        border-bottom: .1rem solid var(--p-border-subdued,#dfe3e8);
        border-top: .1rem solid var(--p-border-subdued,#dfe3e8);

    }

    .search-products{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .search-products-text{
        width: 80%;
    }
}
@primary-color: #5C6AC4;