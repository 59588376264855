.et-dash {
    .et-dash-preview {
        padding: 0 0 0 20px;
        width: 40%;

        .Polaris-Card__Section {
            word-break: break-all;
            overflow: hidden;
        }

        .preview-image {
            width: 280px;
            margin: 0 auto;

            img {
                width: 300px;
                height: 170px;
            }

            .product-name {
                text-align: center;
            }

            .product-add-btn {
                text-align: center;
                background-color: #CACACA;
                height: 34px;
                line-height: 34px;
                margin-top: 10px;
                font-size: 18px;
                color: white;
            }
        }

        .preview {
            margin: 25px 0 0 0;
            a {
                color: #4E5154;
            }
        }
    }
}
@media screen and ( max-width: 767px ){
    .et-dash {
        .et-dash-preview {
            .preview-image {
                width: auto;
            }
        }
    }
}

@media screen and ( max-width: 1366px ){
    .et-dash {
        .et-dash-preview {
            .preview-image {
                width: auto;
            }
        }
    }
}

@primary-color: #5C6AC4;