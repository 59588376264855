.edt-cookies-not-found{
  display: inline-grid;
  padding: 10% 20% 10% 25%;
  .Polaris-Card{
    width: 900px;
  }
  .Polaris-Card__Section:nth-of-type(3){
    background-color: rgb(246,246,247);
  }
}




@primary-color: #5C6AC4;