
.cuntry-group{
    .Polaris-Modal-Section{
        border-bottom: 0.1rem solid var(--p-border-subdued, #dfe3e8);
    }
    .delivery-cuntry{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        
        .provinces-num-group{
            cursor: pointer;
            .provinces-num{
                display: flex;
                span{
                    color: #637381;
                }
            }
            .Polaris-Icon--colorBase svg{
                fill: #637381;
            }
        }
    }
    .provinces-text{
        padding-left: 1.5rem;
    }
}
.country-provinces{
    width: 100px;
    .Polaris-TextStyle--variationStrong{
        font-weight:400;
        font-size: 14px;
    }
}
.select-pro-text{
    position: absolute;
    left: 48rem;
}
.et-delivery-zones{
    margin-top: 20px;
    .Polaris-DataTable__Cell--verticalAlignTop{
        vertical-align: inherit;
    }

    .Polaris-DataTable__Cell--numeric{
        text-align: left;
    }
    .Polaris-Page-Header{
        padding-top:1rem;
    }

    .Polaris-Page{
        padding:0px;
    }
    .pagination {
        margin: auto;
        padding: 2.4rem 1.6rem 0.4rem 1.6rem;;
        text-align: center;
        border-top: .1rem solid var(--p-border-subdued,#dfe3e8);
        
    }
    .et-delivery-zones-list{
        .Polaris-Spinner{
            display: flex;
            justify-content: center;
            padding: 100px;
        }
        .Polaris-Thumbnail {
            border:0px;
            img{
                height: 30px;
                width: 40px;
            }
        }
    }
}
.select-products{
    .Polaris-Spinner{
        display: flex;
        justify-content: center;
        padding: 50px;
    }
}

.Polaris-Thumbnail:after{
    box-shadow: none !important;
}

@primary-color: #5C6AC4;