.et-dash {
    .et-dash-delivery-time {
        padding: 20px 0 0 0;

        .range {
            padding: 30px 0 0 0;

            .Polaris-RangeSlider-DualThumb__Output {
                opacity: 1;
                visibility: visible;
                bottom: var(--p-range-slider-thumb-size-active, var(--p-range-slider-thumb-size-base, 3rem));
            }
        }

        .weekend {
            padding: 20px 0 0 0;
            .Polaris-Choice {
                padding-right: 8%;
            }
        }

        .jump {
            padding: 20px 0 0 0;
            div {
                padding: 5px 0 5px 0;
            }
            p {
                margin-bottom: 0 !important;
            }
        }

        // .Polaris-Layout {
        //     display: inherit;
        //     padding-top: 39rem;
        // }
    }

    .holidays{
        padding-top: 20px;
    }
    .holidays-title{
        display: flex;
        padding-bottom: 5px;
    }
    
    .edt-delivery-tooltip{
        display: flex;
        span{
            margin-left: 2px;
        }
    }
   
}
.Polaris-Tooltip__Wrapper{
    width: 300px;
 }
 .Polaris-Tooltip__Content{
     max-width: 300px  !important;
     min-height:auto !important;
 }
@primary-color: #5C6AC4;