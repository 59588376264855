.et-dash {
    .cart-checkout-checkbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cart-checkout-img{
            display: flex;
            align-items: center;
            img{
                width: 30px;
            }
            
        }
        .cart-checkout-email{
            display: flex;
            align-items: center;
            img{
                width: 30px;
            }
            label{
                margin-left:5px;
            }
            a{
                margin-left:20px;   
            }
        }
    }
    .cart-checkout-one{
        margin-bottom: 1rem;
    }
    .edit-text a{
        color:#006fbb;
    }
    .et-dash-placement {
        padding: 20px 0 0 0;
        .Polaris-Button__Text{
            img{
                width:30px;
            }
        }
        .Polaris-Stack{
            display: inline-flex;
        }
        .cart-checkout {
            width: 49%;

            .Polaris-Card {
                height: 100%;
            }

            .preview {
                padding-top: 0.5rem;
            }
        }
        .code-email-input {
            margin-top:10px;
            width: 100%;
        }
        .placement {
            width: 49%;
            margin-left: 2%;

            .code {
                margin-top: 1rem;
                display: flex;

                .code-input {
                    width: 78%;
                }
                
                .code-btn {
                    margin-left: 2rem;
                }
            }

            .Polaris-Card {
                height: 100%;
            }
        }

        .Polaris-Frame {
            min-height: 0;
        }

        .Polaris-Layout__Section {
            display: flex;
        }
    }
}
.plans-free-div{
    .plans-free-title{
        text-align: center;
        font-size: 20px;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .plans-free-title-bz{
        text-align: center;
        font-size: 20px;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    img{
        width: 40px;
    }
    .plans-free-button{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    button{
        height: 50px;
        border-radius: 0px;
    }
}
@primary-color: #5C6AC4;