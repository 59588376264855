.et-collection-list {
    .search-collections {
        border-bottom: 1px solid var(--p-border-subdued, #dfe3e8);
        padding: 2rem;
    }

    .Polaris-Layout {
        display: block;

        .Polaris-Card {
            border-radius: 0 0 3px 3px;
        }
    }

    .Polaris-Card .Polaris-Card__Header {
        height: 76px;
        line-height: 42px;
        border-bottom: 1px solid #C8C8C8;
    }

    .Polaris-ActionMenu-MenuAction__IconWrapper {
        display: none;
    }

    .Polaris-Page__Content, .Polaris-Page-Header--hasActionMenu {
        padding: 0px;
        margin: 0px;
    }

    .Polaris-Page-Header__ActionMenuWrapper {
        margin-top: 0;
    }

    .Polaris-ActionMenu__ActionsLayout {
        display: block;
    }

    .Polaris-DataTable__Cell:nth-child(2) {
        width: 70%;
    }

    .Polaris-DataTable__Cell:nth-child(4) {
        width: 10%;
        vertical-align: middle;
    }

    .Polaris-DataTable__Cell:nth-child(5) {
        width: 10%;
        vertical-align: middle;
    }

    .Polaris-DataTable__Cell:nth-child(3) {
        width: 16%;
        text-align: left;
        padding-top: 25px;
        padding-left: 12px;
    }

    // .Polaris-DataTable__Cell:nth-child(2) {
    //     padding-top: 25px;
    // }

    .Polaris-DataTable__Cell--numeric {
        text-align: left;
        white-space: normal;
    }

    .Polaris-Page {
        padding: 0;
    }

    .Polaris-EmptyState__Details {
        max-width: 100%;
    }

    .Polaris-EmptyState--newDesignLanguage, .Polaris-EmptyState--withinContentContainer {
        padding: 3rem 2rem;
    }

    .Polaris-EmptyState__Section {
        background-color: #F7F9FA;
    }

    .Polaris-DisplayText--sizeSmall {
        font-size: 1.5rem;
    }

    .pagination {
        margin: auto;
        padding: 2.4rem 1.6rem;
        text-align: center;
        border-top: .1rem solid var(--p-border-subdued,#dfe3e8);
    }

    .Polaris-Frame {
        min-height: auto;
    }

    .delete-search-add-collection-list-text {
        cursor: pointer;
    }

    .view-collection {
        span {
            display: inline-block;
            cursor: pointer;

            svg:hover {
                fill:#007ace;
            }
        }
    }

    .search-collections{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .search-collections-text{
        width: 80%;
    }
}
@primary-color: #5C6AC4;