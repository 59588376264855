.multipleDropdownClassName .ant-picker-dropdown {
  top: 0 !important;
}

.multipleDropdownClassName .ant-picker-cell-selected .ant-picker-cell-inner {
  color: rgba(0, 0, 0, 0.85);

  background: transparent;
}

.multipleDropdownClassName
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  content: none;
}

.ant-select-multiple{
  width: 100% !important;
}
.ant-picker-cell .ant-picker-cell-inner{
  min-width: 33px !important;
}
.ant-picker-content th, .ant-picker-content td{
  text-align: center;
}

.ant-picker-cell .ant-picker-cell-inner:hover{
  color: #000 !important;
  background-color: rgb(245, 245, 245) !important;
}

.ant-picker-decade-panel .ant-picker-content, .ant-picker-year-panel .ant-picker-content, .ant-picker-quarter-panel .ant-picker-content, .ant-picker-month-panel .ant-picker-content{
  height:228px !important;
}