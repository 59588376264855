.info-template-population{
    .Polaris-Modal-Section{
        padding: 0px;
    }
    .info-template-sum{
        padding: 3rem;
    }
    .info-template:hover{
        background-color: #F2F7FE;
    }
    .info-template{
        cursor:pointer;
    }
}
.et-dash {
    .et-dash-edit-setting {
        width: 60%;
        
        .editor {
            .demo-editor {
                border-width: 1px !important;
                border-style: solid !important;
                border-color: rgb(241, 241, 241) !important;
                border-image: initial !important;
                padding: 12px 5px !important;
                border-radius: 2px !important;
            }

            .public-DraftStyleDefault-block {
                margin: 0 0;
            }

            a {
                color: #4E5154;
            }
        }

        .position {
            .Polaris-Connected {
                width: 5rem;
            }

            div h2 {
                font-size: 1.6rem;
                font-weight: 600;
                padding: 20px 0 0 0;
            }
        }

        .datetime {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .select {
                margin-top: -4px;
            }
            .Polaris-Connected {
                width: 5rem;
            }

            div h2 {
                font-size: 1.6rem;
                font-weight: 600;
                padding: 20px 0 0 0;
            }
            .datetime-format{
                width: 25rem;
            }
            .datetime-lang{
                width: 38rem;
            }
        }

        .countdown {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .countdown-time {
                div h2 {
                    font-size: 1.6rem;
                    font-weight: 600;
                    padding: 20px 0 0 0;
                }

                .countdown-time-select {
                    display: flex;
                    margin-top: -7px;

                    .time-colon {
                        font-size: 20px;
                        font-weight: 700;
                        padding: 0 0.5rem;
                    }

                    .selected-left {
                        width: 8rem;
                    }
                    .selected-right {
                        width: 8rem;
                    }
                }
            }

            .countdown-time-format {
                width: 38rem;

                .selected {
                    margin-top: -7px;
                }

                div h2 {
                    font-size: 1.6rem;
                    font-weight: 600;
                    padding: 20px 0 0 0;
                }
            }
        }

        .out-of-stock {
            div h2 {
                font-size: 1.6rem;
                font-weight: 600;
                padding: 20px 0 0 0;
            }

            .out-of-stock-select {
                margin-top: -4px;
                padding-bottom: 1rem;
            }
        }

        .variable-btn {
            padding-top: 5px;
            .Polaris-Button{
                padding: 0.7rem 0.9rem;
            }
            .Polaris-Button:nth-child(1){
                margin-left: 3px;
            }
            .Polaris-Button:nth-child(2),.Polaris-Button:nth-child(3),.Polaris-Button:nth-child(4),.Polaris-Button:nth-child(5),.Polaris-Button:nth-child(6) {
                margin-left: 3px;
                margin-top: 6px;
            }
        }

        .math {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            line-height: 36px;
            margin-top: -10px;

            div > span {
                padding: 0 20px 0 0;
            }
            div > div {
                display: inline-block;
            }

            .Polaris-TextField__Spinner {
                display: none;
            }

            div:nth-child(1),div:nth-child(2),div:nth-child(3),div:nth-child(4) {
                margin: 1px 0 0 0;
            }
        }

        .Polaris-Card__Section {
            margin-top: -14px;
        }

        .edit-setting-radio{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }
    }
    .edit-stting-doubt{
        display: flex;
        align-items: center;
        label{
            margin-right: 5px;
        }
        .Polaris-Icon{
            height: 1.8rem;
            width: 1.8rem;
        }
        
    }
}
.Polaris-Tooltip__Wrapper{
    background-color:#fff;
    color:#000;
}
@primary-color: #5C6AC4;