.et-delivery-zone {
    .country-title{
        font-size: 1.6rem;
        font-weight: 600;
    }
    .country-cart{
        margin-top:5px;
    }
    .country-select{
        margin-left: 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .country-select-text{
            width: 45%;
        }
    }
    .country-custom{
        margin-left: 23px;
        margin-top: 10px;
    }
    .et-delivery-zones {
        .et-delivery-zones-search {
            .flag-icon {
                width: 0.5rem;
                height: 0.5rem;
            }

            .country-name {
                float: right;
                padding-left: 40px;
                padding-right: -3px;
            }
        }

        .search-country {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .search-country-text{
                width: 80%;
            }
            .delete-search-add-country-list-text {
                cursor: pointer;
            }
        }

        .et-delivery-zones-list {
            margin-top: 6px;

            .Polaris-ActionMenu-MenuAction__IconWrapper {
                display: none;
            }
        
            .Polaris-Page__Content, .Polaris-Page-Header--hasActionMenu {
                padding: 0px;
                margin: 0px;
            }
        
            .Polaris-Page-Header__ActionMenuWrapper {
                margin-top: 0;
            }
        
            .Polaris-ActionMenu__ActionsLayout {
                display: block;
            }
        
            .Polaris-DataTable__Cell {
                line-height: 35px;
            }
        
            .Polaris-DataTable__Cell--numeric {
                text-align: left;
                white-space: normal;
            }

            .Polaris-DataTable__Cell:nth-child(3) {
                width: 60%;
            }

            .Polaris-DataTable__Cell:nth-child(4) {
                width: 16%;
                text-align: left;
                padding-left: 12px;
            }
        
            .Polaris-Page {
                padding: 0;
            }

            .Polaris-ResourceItem__Owned {
                margin-top: 1rem;
            }

            .Polaris-EmptyState__Details {
                max-width: 100%;
            }
        
            .Polaris-EmptyState--newDesignLanguage, .Polaris-EmptyState--withinContentContainer {
                padding-bottom: 0rem;
            }
        
            .Polaris-EmptyState__Section {
                background-color: #F7F9FA;
            }

            .Polaris-DisplayText--sizeSmall {
                font-size: 1.5rem;
            }

            .Polaris-Thumbnail__Image {
                width: 36px;
                height: 24px;
                border-radius: 12%;
            }

            .Polaris-Thumbnail::after {
                content: '';
                position: relative;
                z-index: 20;
                display: block;
                padding-bottom: 100%;
                box-shadow: none;
                border-radius: 3px;
            }

            .Polaris-Thumbnail {
                position: relative;
                display: block;
                overflow: hidden;
                background: none;
                min-width: 4rem;
                max-width: 100%;
                border-radius: 3px;
            }

            .delete-country {
                display: inline-block;
                height: 35px;
                vertical-align: text-top;
                cursor: pointer;

                .Polaris-Button--plain svg {
                    fill: #20262F;
                }
            }

            .pagination {
                margin: auto;
                padding: 2.4rem 1.6rem 0.4rem 1.6rem;;
                text-align: center;
                border-top: .1rem solid var(--p-border-subdued,#dfe3e8);
                
            }
        }
    }
}
.cuntry-group{
    .Polaris-Modal-Section{
        border-bottom: 0.1rem solid var(--p-border-subdued, #dfe3e8);
    }
    .delivery-cuntry{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        
        .provinces-num-group{
            cursor: pointer;
            .provinces-num{
                display: flex;
                span{
                    color: #637381;
                }
            }
            .Polaris-Icon--colorBase svg{
                fill: #637381;
            }
        }
    }
    .provinces-text{
        padding-left: 1.5rem;
    }
}
.country-provinces{
    .Polaris-TextStyle--variationStrong{
        font-weight:400;
        font-size: 14px;
    }
}
.select-pro-text{
    position: absolute;
    left: 48rem;
}

@primary-color: #5C6AC4;