@media screen and ( max-width: 768px ){
    .et-faq {
        padding: 20px 1% 40px 1%;

        .et-faq-contact-us-banner {
            margin-bottom: 2rem;
        }

        .Polaris-Frame {
            min-height: 0;
        }

        .Polaris-Banner__Ribbon {
            display: none;
        }
        .et-dash-banner {
            margin-bottom: 2rem;
    
            .banner-title {
                margin-bottom: 10px;
    
                .ant-rate {
                    margin-left: 2rem;
                }
            }
    
            .icon {
                margin-right: 10px;
                font-size: 18px;
            }
    
            .title-text {
                font-size: 16px;
            }
    
            .Polaris-Banner__Ribbon {
                display: none;
            }
        }
    }
}

@media screen and ( min-width: 768px ){
    .et-faq {
        padding: 20px 10% 40px 10%;

        .et-faq-contact-us-banner {
            margin-bottom: 2rem;
        }

        .Polaris-Frame {
            min-height: 0;
        }

        .Polaris-Banner__Ribbon {
            display: none;
        }
        .et-dash-banner {
            margin-bottom: 2rem;
    
            .banner-title {
                margin-bottom: 10px;
    
                .ant-rate {
                    margin-left: 2rem;
                }
            }
    
            .icon {
                margin-right: 10px;
                font-size: 18px;
            }
    
            .title-text {
                font-size: 16px;
            }
    
            .Polaris-Banner__Ribbon {
                display: none;
            }
        }
    }
}
@primary-color: #5C6AC4;