body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.et-tab-tabbtn {
  border-bottom: 0.1rem solid var(--p-border-subdued, #dfe3e8);
}
.et-tab-tabbtn .Polaris-Card {
  background-color: transparent;
  box-shadow: none;
}
.et-tab-tabbtn .Polaris-Tabs__Wrapper {
  border-bottom: none;
}

.info-template-population .Polaris-Modal-Section {
  padding: 0px;
}
.info-template-population .info-template-sum {
  padding: 3rem;
}
.info-template-population .info-template:hover {
  background-color: #F2F7FE;
}
.info-template-population .info-template {
  cursor: pointer;
}
.et-dash .et-dash-edit-setting {
  width: 60%;
}
.et-dash .et-dash-edit-setting .editor .demo-editor {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #f1f1f1 !important;
  border-image: initial !important;
  padding: 12px 5px !important;
  border-radius: 2px !important;
}
.et-dash .et-dash-edit-setting .editor .public-DraftStyleDefault-block {
  margin: 0 0;
}
.et-dash .et-dash-edit-setting .editor a {
  color: #4E5154;
}
.et-dash .et-dash-edit-setting .position .Polaris-Connected {
  width: 5rem;
}
.et-dash .et-dash-edit-setting .position div h2 {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 20px 0 0 0;
}
.et-dash .et-dash-edit-setting .datetime {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.et-dash .et-dash-edit-setting .datetime .select {
  margin-top: -4px;
}
.et-dash .et-dash-edit-setting .datetime .Polaris-Connected {
  width: 5rem;
}
.et-dash .et-dash-edit-setting .datetime div h2 {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 20px 0 0 0;
}
.et-dash .et-dash-edit-setting .datetime .datetime-format {
  width: 25rem;
}
.et-dash .et-dash-edit-setting .datetime .datetime-lang {
  width: 38rem;
}
.et-dash .et-dash-edit-setting .countdown {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.et-dash .et-dash-edit-setting .countdown .countdown-time div h2 {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 20px 0 0 0;
}
.et-dash .et-dash-edit-setting .countdown .countdown-time .countdown-time-select {
  display: flex;
  margin-top: -7px;
}
.et-dash .et-dash-edit-setting .countdown .countdown-time .countdown-time-select .time-colon {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0.5rem;
}
.et-dash .et-dash-edit-setting .countdown .countdown-time .countdown-time-select .selected-left {
  width: 8rem;
}
.et-dash .et-dash-edit-setting .countdown .countdown-time .countdown-time-select .selected-right {
  width: 8rem;
}
.et-dash .et-dash-edit-setting .countdown .countdown-time-format {
  width: 38rem;
}
.et-dash .et-dash-edit-setting .countdown .countdown-time-format .selected {
  margin-top: -7px;
}
.et-dash .et-dash-edit-setting .countdown .countdown-time-format div h2 {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 20px 0 0 0;
}
.et-dash .et-dash-edit-setting .out-of-stock div h2 {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 20px 0 0 0;
}
.et-dash .et-dash-edit-setting .out-of-stock .out-of-stock-select {
  margin-top: -4px;
  padding-bottom: 1rem;
}
.et-dash .et-dash-edit-setting .variable-btn {
  padding-top: 5px;
}
.et-dash .et-dash-edit-setting .variable-btn .Polaris-Button {
  padding: 0.7rem 0.9rem;
}
.et-dash .et-dash-edit-setting .variable-btn .Polaris-Button:nth-child(1) {
  margin-left: 3px;
}
.et-dash .et-dash-edit-setting .variable-btn .Polaris-Button:nth-child(2),
.et-dash .et-dash-edit-setting .variable-btn .Polaris-Button:nth-child(3),
.et-dash .et-dash-edit-setting .variable-btn .Polaris-Button:nth-child(4),
.et-dash .et-dash-edit-setting .variable-btn .Polaris-Button:nth-child(5),
.et-dash .et-dash-edit-setting .variable-btn .Polaris-Button:nth-child(6) {
  margin-left: 3px;
  margin-top: 6px;
}
.et-dash .et-dash-edit-setting .math {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 36px;
  margin-top: -10px;
}
.et-dash .et-dash-edit-setting .math div > span {
  padding: 0 20px 0 0;
}
.et-dash .et-dash-edit-setting .math div > div {
  display: inline-block;
}
.et-dash .et-dash-edit-setting .math .Polaris-TextField__Spinner {
  display: none;
}
.et-dash .et-dash-edit-setting .math div:nth-child(1),
.et-dash .et-dash-edit-setting .math div:nth-child(2),
.et-dash .et-dash-edit-setting .math div:nth-child(3),
.et-dash .et-dash-edit-setting .math div:nth-child(4) {
  margin: 1px 0 0 0;
}
.et-dash .et-dash-edit-setting .Polaris-Card__Section {
  margin-top: -14px;
}
.et-dash .et-dash-edit-setting .edit-setting-radio {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.et-dash .edit-stting-doubt {
  display: flex;
  align-items: center;
}
.et-dash .edit-stting-doubt label {
  margin-right: 5px;
}
.et-dash .edit-stting-doubt .Polaris-Icon {
  height: 1.8rem;
  width: 1.8rem;
}
.Polaris-Tooltip__Wrapper {
  background-color: #fff;
  color: #000;
}

.et-dash .et-dash-preview {
  padding: 0 0 0 20px;
  width: 40%;
}
.et-dash .et-dash-preview .Polaris-Card__Section {
  word-break: break-all;
  overflow: hidden;
}
.et-dash .et-dash-preview .preview-image {
  width: 280px;
  margin: 0 auto;
}
.et-dash .et-dash-preview .preview-image img {
  width: 300px;
  height: 170px;
}
.et-dash .et-dash-preview .preview-image .product-name {
  text-align: center;
}
.et-dash .et-dash-preview .preview-image .product-add-btn {
  text-align: center;
  background-color: #CACACA;
  height: 34px;
  line-height: 34px;
  margin-top: 10px;
  font-size: 18px;
  color: white;
}
.et-dash .et-dash-preview .preview {
  margin: 25px 0 0 0;
}
.et-dash .et-dash-preview .preview a {
  color: #4E5154;
}
@media screen and (max-width: 767px) {
  .et-dash .et-dash-preview .preview-image {
    width: auto;
  }
}
@media screen and (max-width: 1366px) {
  .et-dash .et-dash-preview .preview-image {
    width: auto;
  }
}

.multipleDropdownClassName .ant-picker-dropdown {
  top: 0 !important;
}

.multipleDropdownClassName .ant-picker-cell-selected .ant-picker-cell-inner {
  color: rgba(0, 0, 0, 0.85);

  background: transparent;
}

.multipleDropdownClassName
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  content: none;
}

.ant-select-multiple{
  width: 100% !important;
}
.ant-picker-cell .ant-picker-cell-inner{
  min-width: 33px !important;
}
.ant-picker-content th, .ant-picker-content td{
  text-align: center;
}

.ant-picker-cell .ant-picker-cell-inner:hover{
  color: #000 !important;
  background-color: rgb(245, 245, 245) !important;
}

.ant-picker-decade-panel .ant-picker-content, .ant-picker-year-panel .ant-picker-content, .ant-picker-quarter-panel .ant-picker-content, .ant-picker-month-panel .ant-picker-content{
  height:228px !important;
}
.et-dash .et-dash-delivery-time {
  padding: 20px 0 0 0;
}
.et-dash .et-dash-delivery-time .range {
  padding: 30px 0 0 0;
}
.et-dash .et-dash-delivery-time .range .Polaris-RangeSlider-DualThumb__Output {
  opacity: 1;
  visibility: visible;
  bottom: var(--p-range-slider-thumb-size-active, var(--p-range-slider-thumb-size-base, 3rem));
}
.et-dash .et-dash-delivery-time .weekend {
  padding: 20px 0 0 0;
}
.et-dash .et-dash-delivery-time .weekend .Polaris-Choice {
  padding-right: 8%;
}
.et-dash .et-dash-delivery-time .jump {
  padding: 20px 0 0 0;
}
.et-dash .et-dash-delivery-time .jump div {
  padding: 5px 0 5px 0;
}
.et-dash .et-dash-delivery-time .jump p {
  margin-bottom: 0 !important;
}
.et-dash .holidays {
  padding-top: 20px;
}
.et-dash .holidays-title {
  display: flex;
  padding-bottom: 5px;
}
.et-dash .edt-delivery-tooltip {
  display: flex;
}
.et-dash .edt-delivery-tooltip span {
  margin-left: 2px;
}
.Polaris-Tooltip__Wrapper {
  width: 300px;
}
.Polaris-Tooltip__Content {
  max-width: 300px  !important;
  min-height: auto !important;
}

.et-dash .cart-checkout-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.et-dash .cart-checkout-checkbox .cart-checkout-img {
  display: flex;
  align-items: center;
}
.et-dash .cart-checkout-checkbox .cart-checkout-img img {
  width: 30px;
}
.et-dash .cart-checkout-checkbox .cart-checkout-email {
  display: flex;
  align-items: center;
}
.et-dash .cart-checkout-checkbox .cart-checkout-email img {
  width: 30px;
}
.et-dash .cart-checkout-checkbox .cart-checkout-email label {
  margin-left: 5px;
}
.et-dash .cart-checkout-checkbox .cart-checkout-email a {
  margin-left: 20px;
}
.et-dash .cart-checkout-one {
  margin-bottom: 1rem;
}
.et-dash .edit-text a {
  color: #006fbb;
}
.et-dash .et-dash-placement {
  padding: 20px 0 0 0;
}
.et-dash .et-dash-placement .Polaris-Button__Text img {
  width: 30px;
}
.et-dash .et-dash-placement .Polaris-Stack {
  display: inline-flex;
}
.et-dash .et-dash-placement .cart-checkout {
  width: 49%;
}
.et-dash .et-dash-placement .cart-checkout .Polaris-Card {
  height: 100%;
}
.et-dash .et-dash-placement .cart-checkout .preview {
  padding-top: 0.5rem;
}
.et-dash .et-dash-placement .code-email-input {
  margin-top: 10px;
  width: 100%;
}
.et-dash .et-dash-placement .placement {
  width: 49%;
  margin-left: 2%;
}
.et-dash .et-dash-placement .placement .code {
  margin-top: 1rem;
  display: flex;
}
.et-dash .et-dash-placement .placement .code .code-input {
  width: 78%;
}
.et-dash .et-dash-placement .placement .code .code-btn {
  margin-left: 2rem;
}
.et-dash .et-dash-placement .placement .Polaris-Card {
  height: 100%;
}
.et-dash .et-dash-placement .Polaris-Frame {
  min-height: 0;
}
.et-dash .et-dash-placement .Polaris-Layout__Section {
  display: flex;
}
.plans-free-div .plans-free-title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.plans-free-div .plans-free-title-bz {
  text-align: center;
  font-size: 20px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.plans-free-div img {
  width: 40px;
}
.plans-free-div .plans-free-button {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.plans-free-div button {
  height: 50px;
  border-radius: 0px;
}

/* app to other */
.tracking-pro {
  text-transform: none;
  letter-spacing: normal;
  color: var(--p-text, #212b36);
  margin-top: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif !important;
}
.tracking-pro .title {
  text-transform: none;
  letter-spacing: normal;
  padding-bottom: 1.8rem;
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0;
  color: #212b36;
}
.tracking-pro .Polaris-Card__Section {
  padding-left: 5px;
  padding-top: inherit;
}
.tracking-pro .img {
  max-width: 48px;
  border-radius: 4px;
}
.tracking-pro .text a {
  color: #4989c2;
  text-shadow: none;
  font-weight: 600;
  font-size: 1.6rem;
}
.tracking-pro .text a:hover {
  text-decoration: underline;
}
.tracking-pro .text .tip {
  margin-top: 3px;
  font-size: 1.4rem;
}
.tracking-pro .content {
  padding-left: 15px;
  padding-right: 15px;
}
.tracking-pro .content .small-title {
  font-size: 1.5rem;
  color: #425061;
  text-transform: none;
  letter-spacing: normal;
}
.tracking-pro .content .content-action {
  margin-top: 1.2rem;
  margin-bottom: 2.4rem;
}

@media screen and (max-width: 768px) {
  .et-dash {
    padding: 20px 1% 40px 1%;
  }
}
@media screen and (min-width: 768px) {
  .et-dash {
    padding: 20px 10% 40px 10%;
  }
}
.et-dash-product-shiping {
  padding-bottom: 57%;
  position: relative;
}
.et-dash-product-shiping iframe {
  position: absolute;
  height: 100%;
  top: 0;
}
.et-dash-enabled-center-description {
  display: grid;
}
.et-dash-enabled-center-description .et-dash-enabled-center-description-v1 {
  margin-bottom: 10px;
}
.et-dash-enabled-center-description .et-dash-enabled-center-description-v2 {
  margin-top: 10px;
}
.et-dash-enabled-center-description .et-dash-enabled-center-description-v3 {
  padding-bottom: 10px;
}
.et-dash-enabled-center {
  display: flex;
  align-items: center;
}
.et-dash-enabled-center .et-dash-enabled-center-b {
  margin-right: 10px;
}
.et-dash-enabled-center .et-dash-enabled-center-switch {
  margin-left: 5px;
}
.et-dash-banner .Polaris-Banner__Ribbon {
  display: none;
}
.et-dash .Polaris-Frame {
  min-height: 0;
}
.et-dash .et-dash-product-limit-banner {
  margin-bottom: 2rem;
}
.et-dash .et-dash-banner {
  margin-bottom: 2rem;
}
.et-dash .et-dash-banner .banner-title {
  margin-bottom: 10px;
}
.et-dash .et-dash-banner .banner-title .ant-rate {
  margin-left: 2rem;
}
.et-dash .et-dash-banner .icon {
  margin-right: 10px;
  font-size: 18px;
}
.et-dash .et-dash-banner .title-text {
  font-size: 16px;
}
.et-dash .et-faq-contact-us-banner {
  margin-bottom: 2rem;
}
.et-dash .et-dash-column {
  padding: 20px 0 0 0;
  display: flex;
  width: 100%;
  height: 100%;
  /*声明清除浮动的样式*/
}
.et-dash .et-dash-column .clearfix:before,
.et-dash .et-dash-column .clearfix:after {
  content: "";
  display: table;
}
.et-dash .et-dash-column .clearfix:after {
  clear: both;
}
.et-dash .et-dash-column .clearfix {
  *zoom: 1;
  /*ie6,7 专门清除浮动的样式*/
}
.et-dash .saveBar .Polaris-Frame-ContextualSaveBar {
  height: 5.8rem;
}
.et-dash .saveBar .Polaris-Frame-ContextualSaveBar .Polaris-Frame-ContextualSaveBar__Contents {
  max-width: none;
}
.et-dash .saveBar .Polaris-Frame {
  min-height: 0;
}
.et-dash .saveBar .Polaris-Frame .Polaris-Frame-ContextualSaveBar__LogoContainer {
  display: none;
}
.et-dash .et-dash-load .edit-setting {
  display: flex;
}
.et-dash .et-dash-load .edit-setting .Polaris-Card:nth-child(1) {
  width: 60%;
  margin-top: 2rem;
}
.et-dash .et-dash-load .edit-setting .Polaris-Card:nth-child(2) {
  width: 38%;
  margin-left: 2%;
  height: 0%;
}
.et-dash .et-dash-load .Polaris-SkeletonPage__Page {
  padding: 0;
  max-width: none;
}
.et-dash .et-dash-load .Polaris-SkeletonPage__Page .Polaris-SkeletonPage__Header {
  display: none;
}
.et-dash .et-dash-load .Polaris-SkeletonPage__Page .Polaris-SkeletonPage__Content {
  padding: 0 0 2rem 0;
}
.et-dash .et-dash-load .Polaris-SkeletonPage__Page .Polaris-SkeletonPage__Content .Polaris-Layout {
  display: flow-root;
}
.et-dash .et-dash-load .Polaris-SkeletonPage__Page .Polaris-SkeletonPage__Content .Polaris-Layout .Polaris-Layout__Section {
  padding-top: 0;
}
.et-dash .et-dash-load .Polaris-SkeletonPage__Page .Polaris-SkeletonPage__Content .Polaris-Layout .Polaris-Layout__Section,
.et-dash .et-dash-load .Polaris-SkeletonPage__Page .Polaris-SkeletonPage__Content .Polaris-Layout .Polaris-Layout__AnnotatedSection {
  margin-top: 0;
}
.et-dash .et-dash-load .Polaris-SkeletonPage__Page .Polaris-SkeletonPage__Content .Polaris-Layout .Polaris-Layout__Section--secondary {
  padding-top: 2rem;
}
.et-dash .et-dash-load .Polaris-Frame {
  min-height: 0;
}
.et-dash .dash-is-enabled {
  display: grid;
}
.et-dash .dash-is-enabled label {
  width: 60%;
}
.et-dash .banner-img {
  margin-top: 2rem;
}

.Polaris-Modal-Dialog__Modal .delivery-time-left {
  width: 48%;
  float: left;
}
.Polaris-Modal-Dialog__Modal .delivery-time-right {
  width: 48%;
  float: right;
  margin: 0 0 1.6rem 0;
}
.Polaris-Modal-Dialog__Modal .hide-edt-checkout .Polaris-Choice {
  padding-top: 20px;
}

.et-delivery-zone .country-title {
  font-size: 1.6rem;
  font-weight: 600;
}
.et-delivery-zone .country-cart {
  margin-top: 5px;
}
.et-delivery-zone .country-select {
  margin-left: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.et-delivery-zone .country-select .country-select-text {
  width: 45%;
}
.et-delivery-zone .country-custom {
  margin-left: 23px;
  margin-top: 10px;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-search .flag-icon {
  width: 0.5rem;
  height: 0.5rem;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-search .country-name {
  float: right;
  padding-left: 40px;
  padding-right: -3px;
}
.et-delivery-zone .et-delivery-zones .search-country {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.et-delivery-zone .et-delivery-zones .search-country .search-country-text {
  width: 80%;
}
.et-delivery-zone .et-delivery-zones .search-country .delete-search-add-country-list-text {
  cursor: pointer;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list {
  margin-top: 6px;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-ActionMenu-MenuAction__IconWrapper {
  display: none;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-Page__Content,
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-Page-Header--hasActionMenu {
  padding: 0px;
  margin: 0px;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-Page-Header__ActionMenuWrapper {
  margin-top: 0;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-ActionMenu__ActionsLayout {
  display: block;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-DataTable__Cell {
  line-height: 35px;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-DataTable__Cell--numeric {
  text-align: left;
  white-space: normal;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-DataTable__Cell:nth-child(3) {
  width: 60%;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-DataTable__Cell:nth-child(4) {
  width: 16%;
  text-align: left;
  padding-left: 12px;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-Page {
  padding: 0;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-ResourceItem__Owned {
  margin-top: 1rem;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-EmptyState__Details {
  max-width: 100%;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-EmptyState--newDesignLanguage,
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-EmptyState--withinContentContainer {
  padding-bottom: 0rem;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-EmptyState__Section {
  background-color: #F7F9FA;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-DisplayText--sizeSmall {
  font-size: 1.5rem;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-Thumbnail__Image {
  width: 36px;
  height: 24px;
  border-radius: 12%;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-Thumbnail::after {
  content: '';
  position: relative;
  z-index: 20;
  display: block;
  padding-bottom: 100%;
  box-shadow: none;
  border-radius: 3px;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .Polaris-Thumbnail {
  position: relative;
  display: block;
  overflow: hidden;
  background: none;
  min-width: 4rem;
  max-width: 100%;
  border-radius: 3px;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .delete-country {
  display: inline-block;
  height: 35px;
  vertical-align: text-top;
  cursor: pointer;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .delete-country .Polaris-Button--plain svg {
  fill: #20262F;
}
.et-delivery-zone .et-delivery-zones .et-delivery-zones-list .pagination {
  margin: auto;
  padding: 2.4rem 1.6rem 0.4rem 1.6rem;
  text-align: center;
  border-top: 0.1rem solid var(--p-border-subdued, #dfe3e8);
}
.cuntry-group .Polaris-Modal-Section {
  border-bottom: 0.1rem solid var(--p-border-subdued, #dfe3e8);
}
.cuntry-group .delivery-cuntry {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.cuntry-group .delivery-cuntry .provinces-num-group {
  cursor: pointer;
}
.cuntry-group .delivery-cuntry .provinces-num-group .provinces-num {
  display: flex;
}
.cuntry-group .delivery-cuntry .provinces-num-group .provinces-num span {
  color: #637381;
}
.cuntry-group .delivery-cuntry .provinces-num-group .Polaris-Icon--colorBase svg {
  fill: #637381;
}
.cuntry-group .provinces-text {
  padding-left: 1.5rem;
}
.country-provinces .Polaris-TextStyle--variationStrong {
  font-weight: 400;
  font-size: 14px;
}
.select-pro-text {
  position: absolute;
  left: 48rem;
}

@media screen and (max-width: 768px) {
  .et-delivery-zone {
    padding: 20px 1% 40px 1%;
  }
}
@media screen and (min-width: 768px) {
  .et-delivery-zone {
    padding: 20px 10% 40px 10%;
  }
}
.et-delivery-zone .Polaris-Frame {
  min-height: 0;
}
@media (min-width: 30.625em) {
  .Polaris-Page-Header--mobileView .Polaris-Page-Header__ActionMenuWrapper {
    top: 0rem;
  }
}
@media (max-width: 1024px) {
  .Polaris-Modal-Dialog__Modal {
    bottom: 15rem;
  }
}
@media (max-width: 736px) {
  .Polaris-Modal-Dialog__Modal {
    bottom: 0rem;
  }
}

.Polaris-Modal-Dialog__Modal .delivery-time-left {
  width: 48%;
  float: left;
}
.Polaris-Modal-Dialog__Modal .delivery-time-right {
  width: 48%;
  float: right;
  margin: 0 0 1.6rem 0;
}
.Polaris-Modal-Dialog__Modal .hide-edt-checkout .Polaris-Choice {
  padding-top: 20px;
}

.et-product-list .Polaris-Thumbnail {
  border: 0px;
}
.et-product-list .Polaris-Thumbnail img {
  height: 40px;
  width: 40px;
}
.et-product-list .Polaris-Layout {
  display: block;
}
.et-product-list .Polaris-Layout .Polaris-Card {
  border-radius: 0 0 3px 3px;
}
.et-product-list .Polaris-Card .Polaris-Card__Header {
  height: 76px;
  line-height: 42px;
  border-bottom: 1px solid #C8C8C8;
}
.et-product-list .change-product {
  width: 100%;
}
.et-product-list .Polaris-ActionMenu-MenuAction__IconWrapper {
  display: none;
}
.et-product-list .Polaris-Page__Content,
.et-product-list .Polaris-Page-Header--hasActionMenu {
  padding: 0px;
  margin: 0px;
}
.et-product-list .Polaris-Page-Header__ActionMenuWrapper {
  margin-top: 0;
}
.et-product-list .Polaris-ActionMenu__ActionsLayout {
  display: block;
}
.et-product-list .Polaris-DataTable__Cell:nth-child(3) {
  width: 50%;
}
.et-product-list .Polaris-DataTable__Cell:nth-child(4) {
  width: 5%;
}
.et-product-list .Polaris-Badge {
  border: 0px;
}
.et-product-list .Polaris-DataTable__Cell:nth-child(5) {
  width: 35%;
}
.et-product-list .Polaris-DataTable__Cell:nth-child(6) {
  width: 5%;
}
.et-product-list .Polaris-DataTable__Cell--numeric {
  text-align: left;
  white-space: normal;
}
.et-product-list .Polaris-Page {
  padding: 0;
}
.et-product-list .Polaris-EmptyState__Details {
  max-width: 100%;
}
.et-product-list .Polaris-EmptyState--newDesignLanguage,
.et-product-list .Polaris-EmptyState--withinContentContainer {
  padding: 3rem 2rem;
}
.et-product-list .Polaris-EmptyState__Section {
  background-color: #F7F9FA;
}
.et-product-list .Polaris-DisplayText--sizeSmall {
  font-size: 1.5rem;
}
.et-product-list .pagination {
  margin: auto;
  padding: 2.4rem 1.6rem;
  text-align: center;
  border-top: 0.1rem solid var(--p-border-subdued, #dfe3e8);
}
.et-product-list .Polaris-Frame {
  min-height: auto;
}
.et-product-list .delete-search-add-product-list-text {
  cursor: pointer;
}
.et-product-list .view-product {
  margin-top: 2px;
}
.et-product-list .view-product span {
  display: inline-block;
  cursor: pointer;
}
.et-product-list .view-product span svg:hover {
  fill: #007ace;
}
.et-product-list .Polaris-DataTable__Cell--verticalAlignTop {
  vertical-align: middle;
}
.et-product-list .product-list-loding .Polaris-Spinner {
  position: absolute;
  top: 30rem;
  left: 50rem;
}
.et-product-list .product-list-title {
  cursor: pointer;
}
.et-product-list .tr-background {
  background-color: #d5d4ee;
  border-bottom: 0.1rem solid var(--p-border-subdued, #dfe3e8);
  border-top: 0.1rem solid var(--p-border-subdued, #dfe3e8);
}
.et-product-list .search-products {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.et-product-list .search-products-text {
  width: 80%;
}

.product-edit-title {
  margin-bottom: 8px;
  font-size: 22px;
}
.out-of-stock-message {
  margin-top: 10px;
}
.product-edit-editor .editor .demo-editor {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #f1f1f1 !important;
  border-image: initial !important;
  padding: 12px 5px !important;
  border-radius: 2px !important;
}
.product-edit-editor .editor .public-DraftStyleDefault-block {
  margin: 0 0;
}
.product-edit-editor .editor a {
  color: #4E5154;
}
.product-edit-editor .out-of-stock {
  margin-top: 10px;
}
.product-edit-editor .range {
  padding: 10px 0 0 0;
}
.product-edit-editor .range .Polaris-RangeSlider-DualThumb__Output {
  opacity: 1;
  visibility: visible;
  bottom: var(--p-range-slider-thumb-size-active, var(--p-range-slider-thumb-size-base, 3rem));
}
.product-edit-editor .range h2 {
  margin-bottom: 40px;
}
.product-edit-zones {
  margin-top: 20px;
}

.cuntry-group .Polaris-Modal-Section {
  border-bottom: 0.1rem solid var(--p-border-subdued, #dfe3e8);
}
.cuntry-group .delivery-cuntry {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.cuntry-group .delivery-cuntry .provinces-num-group {
  cursor: pointer;
}
.cuntry-group .delivery-cuntry .provinces-num-group .provinces-num {
  display: flex;
}
.cuntry-group .delivery-cuntry .provinces-num-group .provinces-num span {
  color: #637381;
}
.cuntry-group .delivery-cuntry .provinces-num-group .Polaris-Icon--colorBase svg {
  fill: #637381;
}
.cuntry-group .provinces-text {
  padding-left: 1.5rem;
}
.country-provinces {
  width: 100px;
}
.country-provinces .Polaris-TextStyle--variationStrong {
  font-weight: 400;
  font-size: 14px;
}
.select-pro-text {
  position: absolute;
  left: 48rem;
}
.et-delivery-zones {
  margin-top: 20px;
}
.et-delivery-zones .Polaris-DataTable__Cell--verticalAlignTop {
  vertical-align: inherit;
}
.et-delivery-zones .Polaris-DataTable__Cell--numeric {
  text-align: left;
}
.et-delivery-zones .Polaris-Page-Header {
  padding-top: 1rem;
}
.et-delivery-zones .Polaris-Page {
  padding: 0px;
}
.et-delivery-zones .pagination {
  margin: auto;
  padding: 2.4rem 1.6rem 0.4rem 1.6rem;
  text-align: center;
  border-top: 0.1rem solid var(--p-border-subdued, #dfe3e8);
}
.et-delivery-zones .et-delivery-zones-list .Polaris-Spinner {
  display: flex;
  justify-content: center;
  padding: 100px;
}
.et-delivery-zones .et-delivery-zones-list .Polaris-Thumbnail {
  border: 0px;
}
.et-delivery-zones .et-delivery-zones-list .Polaris-Thumbnail img {
  height: 30px;
  width: 40px;
}
.select-products .Polaris-Spinner {
  display: flex;
  justify-content: center;
  padding: 50px;
}
.Polaris-Thumbnail:after {
  box-shadow: none !important;
}

.Polaris-Modal-Dialog__Modal .delivery-time-left {
  width: 48%;
  float: left;
}
.Polaris-Modal-Dialog__Modal .delivery-time-right {
  width: 48%;
  float: right;
  margin: 0 0 1.6rem 0;
}
.Polaris-Modal-Dialog__Modal .hide-edt-checkout .Polaris-Choice {
  padding-top: 20px;
}

.et-collection-list .search-collections {
  border-bottom: 1px solid var(--p-border-subdued, #dfe3e8);
  padding: 2rem;
}
.et-collection-list .Polaris-Layout {
  display: block;
}
.et-collection-list .Polaris-Layout .Polaris-Card {
  border-radius: 0 0 3px 3px;
}
.et-collection-list .Polaris-Card .Polaris-Card__Header {
  height: 76px;
  line-height: 42px;
  border-bottom: 1px solid #C8C8C8;
}
.et-collection-list .Polaris-ActionMenu-MenuAction__IconWrapper {
  display: none;
}
.et-collection-list .Polaris-Page__Content,
.et-collection-list .Polaris-Page-Header--hasActionMenu {
  padding: 0px;
  margin: 0px;
}
.et-collection-list .Polaris-Page-Header__ActionMenuWrapper {
  margin-top: 0;
}
.et-collection-list .Polaris-ActionMenu__ActionsLayout {
  display: block;
}
.et-collection-list .Polaris-DataTable__Cell:nth-child(2) {
  width: 70%;
}
.et-collection-list .Polaris-DataTable__Cell:nth-child(4) {
  width: 10%;
  vertical-align: middle;
}
.et-collection-list .Polaris-DataTable__Cell:nth-child(5) {
  width: 10%;
  vertical-align: middle;
}
.et-collection-list .Polaris-DataTable__Cell:nth-child(3) {
  width: 16%;
  text-align: left;
  padding-top: 25px;
  padding-left: 12px;
}
.et-collection-list .Polaris-DataTable__Cell--numeric {
  text-align: left;
  white-space: normal;
}
.et-collection-list .Polaris-Page {
  padding: 0;
}
.et-collection-list .Polaris-EmptyState__Details {
  max-width: 100%;
}
.et-collection-list .Polaris-EmptyState--newDesignLanguage,
.et-collection-list .Polaris-EmptyState--withinContentContainer {
  padding: 3rem 2rem;
}
.et-collection-list .Polaris-EmptyState__Section {
  background-color: #F7F9FA;
}
.et-collection-list .Polaris-DisplayText--sizeSmall {
  font-size: 1.5rem;
}
.et-collection-list .pagination {
  margin: auto;
  padding: 2.4rem 1.6rem;
  text-align: center;
  border-top: 0.1rem solid var(--p-border-subdued, #dfe3e8);
}
.et-collection-list .Polaris-Frame {
  min-height: auto;
}
.et-collection-list .delete-search-add-collection-list-text {
  cursor: pointer;
}
.et-collection-list .view-collection span {
  display: inline-block;
  cursor: pointer;
}
.et-collection-list .view-collection span svg:hover {
  fill: #007ace;
}
.et-collection-list .search-collections {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.et-collection-list .search-collections-text {
  width: 80%;
}

@media screen and (max-width: 768px) {
  .et-cp-content {
    padding: 20px 1% 40px 1%;
  }
}
@media screen and (min-width: 768px) {
  .et-cp-content {
    padding: 20px 10% 40px 10%;
  }
}
.et-cp-content {
  display: flex;
}
.et-cp-content .et-collection-product {
  padding-right: 2%;
  width: 53%;
}
.et-cp-content .et-collection-product .Polaris-Card .Polaris-Card__Header {
  float: right;
  height: 0px;
}
.et-cp-content .et-collection-product .Polaris-Card .Polaris-Card__Header .Polaris-ButtonGroup {
  margin-top: -1.2rem;
  text-decoration: none;
}
.et-cp-content .et-collection-product .edt-sync-product-slow {
  border: 1px solid #212b36;
  padding: 8px;
  border-radius: 3px;
  color: #212b36;
  text-decoration: none !important;
}
.et-cp-content .et-collection-product .edt-sync-product-slow:hover {
  color: #5c6ac4;
  border-color: #5c6ac4;
  text-decoration: none !important;
}
.et-cp-content .et-edit-content-zong {
  width: 45%;
}
.et-cp-content .et-edit-content {
  width: 100%;
}
.et-cp-content .Polaris-Frame {
  display: contents !important;
}
.saveBar .Polaris-Frame-ContextualSaveBar {
  height: 5.8rem;
}
.saveBar .Polaris-Frame-ContextualSaveBar .Polaris-Frame-ContextualSaveBar__Contents {
  max-width: none;
}
.saveBar .Polaris-Frame {
  min-height: 0;
}
.saveBar .Polaris-Frame .Polaris-Frame-ContextualSaveBar__LogoContainer {
  display: none;
}
.edt-product-sync-content ul {
  list-style: disc;
  margin: 4px 0px 4px 29px;
}
.edt-product-sync-content button {
  display: block;
  margin: 0 auto;
  padding: 0 80px;
  color: #5c6ac4;
  border-color: #5c6ac4;
}
.edt-product-sync-content button:hover {
  color: #5c6ac4;
  text-decoration: none;
  border-color: #5c6ac4;
}

@media screen and (max-width: 768px) {
  .et-plan {
    padding: 20px 1% 40px 1%;
  }
  .et-plan .plan-switch {
    display: flex;
    align-items: center;
    padding-top: 20px;
  }
  .et-plan .plan-switch .Polaris-Stack__Item span {
    padding-right: 20px;
  }
  .et-plan .Polaris-Page .Polaris-Page__Content {
    margin-top: 0;
  }
  .et-plan .Polaris-Page .Polaris-Page__Content .Polaris-Banner__Dismiss {
    display: none;
  }
  .et-plan .Polaris-Page .Polaris-Page__Content .Polaris-Layout {
    padding-top: 10px;
  }
  .et-plan .Polaris-Page .Polaris-Page__Content .Polaris-Frame {
    min-height: 0;
  }
  .et-plan .Polaris-Stack--alignmentCenter {
    display: grid;
    justify-content: center;
  }
  .et-plan .Polaris-Stack--alignmentCenter .Polaris-Stack__Item {
    text-align: center;
  }
  .et-plan .plan-discount-twenty-five {
    padding: 5px;
    background-color: red;
    margin-left: 5px;
    border-radius: 21px;
    font-size: 14px;
    color: #fff;
  }
  .et-plan .Polaris-DisplayText--sizeLarge {
    font-weight: 700;
    color: red;
  }
  .et-plan .Polaris-DisplayText--sizeSmall {
    font-size: 1.5rem;
    color: #6D6D6D;
  }
  .et-plan .Polaris-DisplayText--sizeSmall .plans-package-title {
    font-size: 2rem;
    font-weight: 700;
  }
  .et-plan .plans-free {
    height: 67px;
    display: flex;
    align-items: center;
  }
  .et-plan .plans-discount-price {
    font-size: 20px;
    font-weight: 400;
    text-decoration: line-through;
  }
  .plan-maring {
    margin-bottom: 30px;
  }
  .plan-sanjiao {
    width: 90px;
    float: right;
    margin-right: -20px;
    margin-top: -20px;
  }
  .plan-free-tu .Polaris-Stack {
    margin-left: 30px;
    margin-top: -14px;
  }
}
@media screen and (min-width: 768px) {
  .et-plan {
    padding: 20px 10% 40px 10%;
  }
  .et-plan .plan-switch {
    display: flex;
    align-items: center;
    padding-top: 20px;
  }
  .et-plan .plan-switch .Polaris-Stack__Item span {
    padding-right: 20px;
  }
  .et-plan .Polaris-Page {
    margin: 0px;
    padding: 0px;
  }
  .et-plan .Polaris-Page .Polaris-Page__Content {
    margin-top: 0;
  }
  .et-plan .Polaris-Page .Polaris-Page__Content .Polaris-Banner__Dismiss {
    display: none;
  }
  .et-plan .Polaris-Page .Polaris-Page__Content .Polaris-Layout {
    padding-top: 10px;
  }
  .et-plan .Polaris-Page .Polaris-Page__Content .Polaris-Frame {
    min-height: 0;
  }
  .et-plan .Polaris-Stack--alignmentCenter {
    display: grid;
    justify-content: center;
  }
  .et-plan .Polaris-Stack--alignmentCenter .Polaris-Stack__Item {
    text-align: center;
  }
  .et-plan .plan-discount-twenty-five {
    padding: 5px;
    background-color: red;
    margin-left: 5px;
    border-radius: 21px;
    font-size: 14px;
    color: #fff;
  }
  .et-plan .Polaris-DisplayText--sizeLarge {
    font-weight: 700;
    color: red;
  }
  .et-plan .Polaris-DisplayText--sizeSmall {
    font-size: 1.5rem;
    color: #6D6D6D;
  }
  .et-plan .Polaris-DisplayText--sizeSmall .plans-package-title {
    font-size: 2rem;
    font-weight: 700;
  }
  .et-plan .plans-free {
    height: 67px;
    display: flex;
    align-items: center;
  }
  .et-plan .plans-discount-price {
    font-size: 20px;
    font-weight: 400;
    text-decoration: line-through;
  }
  .plan-maring {
    margin-bottom: 30px;
  }
  .Polaris-Page {
    max-width: 100%;
  }
  .plan-sanjiao {
    width: 90px;
    float: right;
    margin-right: -20px;
    margin-top: -20px;
  }
  .plan-free-tu .Polaris-Stack {
    margin-left: 30px;
    margin-top: -14px;
  }
  .plans-free-img {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .plans-free-img img {
    width: 80px;
  }
  .plans-free-title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
  .plan-tan-k .Polaris-Modal-Section {
    background-color: #5C6AC3;
  }
  .plan-tan-k .plans-free-xx {
    text-align: center;
  }
  .plan-button-margin {
    margin-top: 31px;
  }
  .plan-button-margin-pr {
    margin-top: 66px;
  }
}

@media screen and (max-width: 768px) {
  .et-faq {
    padding: 20px 1% 40px 1%;
  }
  .et-faq .et-faq-contact-us-banner {
    margin-bottom: 2rem;
  }
  .et-faq .Polaris-Frame {
    min-height: 0;
  }
  .et-faq .Polaris-Banner__Ribbon {
    display: none;
  }
  .et-faq .et-dash-banner {
    margin-bottom: 2rem;
  }
  .et-faq .et-dash-banner .banner-title {
    margin-bottom: 10px;
  }
  .et-faq .et-dash-banner .banner-title .ant-rate {
    margin-left: 2rem;
  }
  .et-faq .et-dash-banner .icon {
    margin-right: 10px;
    font-size: 18px;
  }
  .et-faq .et-dash-banner .title-text {
    font-size: 16px;
  }
  .et-faq .et-dash-banner .Polaris-Banner__Ribbon {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .et-faq {
    padding: 20px 10% 40px 10%;
  }
  .et-faq .et-faq-contact-us-banner {
    margin-bottom: 2rem;
  }
  .et-faq .Polaris-Frame {
    min-height: 0;
  }
  .et-faq .Polaris-Banner__Ribbon {
    display: none;
  }
  .et-faq .et-dash-banner {
    margin-bottom: 2rem;
  }
  .et-faq .et-dash-banner .banner-title {
    margin-bottom: 10px;
  }
  .et-faq .et-dash-banner .banner-title .ant-rate {
    margin-left: 2rem;
  }
  .et-faq .et-dash-banner .icon {
    margin-right: 10px;
    font-size: 18px;
  }
  .et-faq .et-dash-banner .title-text {
    font-size: 16px;
  }
  .et-faq .et-dash-banner .Polaris-Banner__Ribbon {
    display: none;
  }
}

.edt-cookies-not-found {
  display: inline-grid;
  padding: 10% 20% 10% 25%;
}
.edt-cookies-not-found .Polaris-Card {
  width: 900px;
}
.edt-cookies-not-found .Polaris-Card__Section:nth-of-type(3) {
  background-color: #f6f6f7;
}

@media screen and (max-width: 768px) {
  .et-admin .et-tab-tabbtn {
    padding: 0 1%;
  }
}
@media screen and (min-width: 768px) {
  .et-admin .et-tab-tabbtn {
    padding: 0 10%;
  }
}

